@keyframes loader {
	25% {
		transform: perspective(75px) rotateX(180deg) rotateY(0);
	}
	50% {
		transform: perspective(75px) rotateX(180deg) rotateY(180deg);
	}
	75% {
		transform: perspective(75px) rotateX(0) rotateY(180deg);
	}
	100% {
		transform: perspective(75px) rotateX(0) rotateY(0);
	}
}

.loader {
	margin-right: 10px;

	> div {
		animation-fill-mode: both;
		color: #fff;
		width: 25px;
		margin: auto;
		height: 25px;
		background: #252525;
		animation: loader 5s 0s ease-in-out infinite;
	}
}

.container {
	font-size: 18px;
	height: 25px;
    margin: auto;
    margin-top: 350px;

	> div {
		float: left;
	}
}