
:global(.rs-steps-item) {
	overflow: visible !important;
}

.steps {
	margin: auto;
	display: table;
	vertical-align: top;

	.step {

		> h2 {
			font-size: 16px;
			padding: 0;
			margin: 0;
			margin-top: 6px;
			line-height: normal;
			text-transform: uppercase;
			position: relative;
			overflow: normal;

			> span {
				left: 0;
				top: -10px;
				position: absolute;
				font-size: 10px;
				opacity: 0.8;
			}
		}

		> .stepImages {
			> img {
				margin-top: 25px;
				margin-right: 10px;
				width: 100px;
				border: 1px solid #b4adada6;
				padding: 2px;
				opacity: 0.85;
				cursor: pointer;

				&:hover {
					border-color: rgb(52, 152, 255);
					opacity: 1;
					transition: 400ms 0ms ease-in-out all;
				}
			}
		}

		> .stepDescription {
			margin-top: 12px;
			font-size: 12px;
			text-transform: uppercase;
		}
	}
}