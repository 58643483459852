body {
	padding: 20px;
}

.fieldRow {
	margin-bottom: 20px;
}

.App {
	width: calc(375px - 40px);
    margin: auto;
    position: relative;
}

.reports {
    margin-top: 20px;

    .report {
        border: 1px solid #ccc;
        padding: 15px;

        .date {
            font-size: 24px;
        }

        .status {
            font-weight: bold;
        }
    }
}


.stepImages {
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;

    .stepImage {
        margin-right: 10px;
        border: 1px solid #b4adada6;
        padding: 2px;
        opacity: 0.85;
        float: left;
        cursor: pointer;
        
        img {
            width: 100px;
        }

        .stepImageDeleteIcon {
            display: none;
        }

        .stepImageContainer {
            position: relative;
            float: left;
            background-color: black;
        }

        &:hover {
            border-color: rgb(52, 152, 255);
            img {
                opacity: 0.5;
            }
            transition: 400ms 0ms ease-in-out all;
            
            .stepImageDeleteIcon {
                display: block;
                position: absolute;
                cursor: pointer;
                bottom: 2px;
                left: 6px;
            }
        }
    }
}

.dropzone {
    clear: both;
    cursor: pointer;
    margin-top: 15px;
    display: block;
    text-transform: uppercase;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
    border: 2px dashed rgb(13, 60, 97);
    border-radius: 5px;
    font-size: 24px;
    color: rgb(13, 60, 97);
    margin-bottom: 15px;;
}

.stepsContainer {
    margin-bottom: 20px;

    :global(.rs-steps-item) {
        overflow: hidden !important;
    }
}

:global([class="MuiDialog-root"][role="presentation"]) {
    z-index: 10000 !important;
}

.passportBackgroundImage {
    background-image: url('../../images/passport-demo.jpg');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 32px 0px;
}