.container {
    position: absolute;
    padding: 10px;
	left: -5px;
	right: -5px;
	top: -5px;
    bottom: -5px;
    z-index: 10000;
    box-sizing: border-box;
    background-color: #fff;
}